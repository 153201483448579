export const heightTableStyle = (className: string) => {
    const wrapper = document.getElementsByClassName(className)[0] as HTMLElement;
    let height = 0;

    if (wrapper) {
        height = wrapper.offsetTop;
    }

    return {height: 'calc(100vh - ' + height + 'px)'};
};
